import React, { useRef, useState, useCallback } from 'react'
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  InputLabel,
} from './file-upload.styles'
import IconFile from '../../icons/IconFile'
import PropTypes from 'prop-types'
import { CloudArrowUpIcon } from '@heroicons/react/24/solid'

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 26214400

const convertNestedObjectToArray = nestedObj => Object.keys(nestedObj).map(key => nestedObj[key])

const mapType = type => {
  if (type && type.indexOf('/')) {
    return type.substring(type.indexOf('/') + 1)
  }
  return type
}

const FileUpload = ({
  label,
  updateFilesCb,
  id,
  UploadClassName,
  required,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null)
  const [files, setFiles] = useState({})
  const [dragOver, setDragOver] = useState(false)

  const handleUploadBtnClick = useCallback(() => {
    fileInputField.current.click()
  }, [fileInputField])

  const addNewFiles = useCallback(newFiles => {
    for (let file of newFiles) {
      if (!otherProps.multiple) {
        return { file }
      }
      files[file.name] = file
    }
    return { ...files }
  }, [])

  const callUpdateFilesCb = useCallback(
    f => {
      const filesAsArray = convertNestedObjectToArray(f).filter(fil => isCorrect(fil))
      updateFilesCb(filesAsArray)
    },
    [updateFilesCb]
  )

  const handleNewFileUpload = useCallback(
    e => {
      const { files: newFiles } = e.target
      if (newFiles.length) {
        let updatedFiles = addNewFiles(newFiles)
        setFiles(updatedFiles)
        callUpdateFilesCb(updatedFiles)
      }
    },
    [addNewFiles, setFiles, callUpdateFilesCb]
  )

  const removeFile = useCallback(
    fileName => {
      delete files[fileName]
      setFiles({ ...files })
      callUpdateFilesCb({ ...files })
    },
    [callUpdateFilesCb]
  )

  const handleDragOver = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
      setDragOver(true)
    },
    [setDragOver]
  )

  const handleDragLeave = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      setDragOver(false)
    },
    [setDragOver]
  )

  const handleDrop = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()
      setDragOver(false)
      const fileList = e.dataTransfer.files
      if (fileList.length) {
        let updatedFiles = addNewFiles(fileList)
        setFiles(updatedFiles)
        callUpdateFilesCb(updatedFiles)
      }
    },
    [setFiles, callUpdateFilesCb, setDragOver]
  )

  const isCorrectType = useCallback(
    file => {
      if (!otherProps.accept) return true
      let goodType = false
      otherProps.accept.split(',').forEach(extension => {
        if (file.name.indexOf(extension.trim()) === file.name.length - extension.trim().length) {
          goodType = true
        }
      })
      return goodType
    },
    [otherProps]
  )

  const isCorrectSize = useCallback(
    file => {
      return file.size < maxFileSizeInBytes
    },
    [maxFileSizeInBytes]
  )

  const isCorrect = useCallback(
    file => {
      return isCorrectSize(file) && isCorrectType(file)
    },
    [isCorrectSize, isCorrectType]
  )

  return (
    <div className=" w-full flex flex-col ">
      <FileUploadContainer
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={
          (dragOver && 'bg-blue-50 border-black') +
          ' flex justify-center items-center w-full lg:h-[12rem] p-2 '
        }
      >
        <CloudArrowUpIcon className="w-16 text-cyan-400" />
        <DragDropText>Drag and drop files to upload, or</DragDropText>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
          <span> Browse {otherProps.multiple ? 'images' : 'an image'}</span>
        </UploadFileBtn>
        <p className="text-sm">
          * Max. file size: <b>40 MB</b>
          <br />
          Supported formats: <b>JPG, JPEG, TIFF, PNG</b>
        </p>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          id={id}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
      {files && (Object.keys(files) || []).length <= 0 ? (
        <div className="text-red-600 h-[7rem]">Add Photos *</div>
      ) : (
        <FilePreviewContainer
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className={(dragOver && 'bg-blue-50 ') + ' h-[7rem] '}
        >
          <PreviewList>
            {Object.keys(files).map(fileName => {
              let file = files[fileName]
              let isImageFile = file.type.split('/')[0] === 'image'
              return (
                <PreviewContainer key={fileName}>
                  <figure className={'relative '}>
                    {isImageFile ? (
                      <ImagePreview
                        src={URL.createObjectURL(file)}
                        alt={`file preview ${file.name}`}
                        title={file.name}
                        className={
                          (!isCorrect(file) && ' border-4 border-red-600 opacity-20') + ' border'
                        }
                      />
                    ) : (
                      <IconFile
                        className={
                          'h-full w-full object-contain ' +
                          (isCorrect(file)
                            ? 'text-gray-400'
                            : 'text-red-600 border-4 border-red-600 opacity-20 ')
                        }
                        text={mapType(file.type)}
                        title={file.name}
                      />
                    )}
                    {/* <figcaption
                      className={
                        ' items-center w-full bg-white ' + (isCorrect(file) ? '' : 'text-red-600')
                      }
                      title={file.name}
                    >
                      {file.name}
                    </figcaption> */}
                    {!isCorrect(file) && (
                      <div
                        className={
                          'text-red-600 font-bold absolute top-0 aspect-square p-4 flex text-center items-center'
                        }
                      >
                        {isCorrectSize(file) ? 'Wrong file format' : 'File Size Too Large'}
                      </div>
                    )}
                    <button
                      onClick={() => removeFile(fileName)}
                      className={
                        'cursor-pointer absolute -top-1 -right-1 rounded-full bg-red-600 text-white font-bold text-center h-7 w-7 text-sm'
                      }
                    >
                      &#10005;
                    </button>
                  </figure>
                </PreviewContainer>
              )
            })}
          </PreviewList>
        </FilePreviewContainer>
      )}
    </div>
  )
}
FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  updateFilesCb: PropTypes.func.isRequired,
  maxFileSizeInBytes: PropTypes.number,
  files: PropTypes.object,
  setFiles: PropTypes.func,
}

export default FileUpload
