import * as React from 'react'
import { memo } from 'react'

const IconFile = memo(({ className, text, title }) => {
  return (
    <svg role="img" aria-label={'File ' + text} className={'icon-file ' + className}
         version="1.1"
         xmlns="http://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink"
         viewBox="0 0 200 200"
         title={title}
    >
      <path
        fill="#ddd" className={'fill-current'}
        d="M136.87,26.47H50.43V168.38h107V47.03l-20.56-20.56ZM53.43,165.38V29.47h80.88v18.18c0,.83,.67,1.5,1.5,1.5h18.62v116.22H53.43Z"/>
      <text textAnchor={'middle'} x="105" y="50%" fill="#ddd"
            className={'fill-current font-bold text-3xl'}>{text.toUpperCase()}</text>
    </svg>
  )
})

export default IconFile